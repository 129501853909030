




import Vue from 'vue'
import Card from './components/Card.vue';

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {

}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	components: {
		Card
	}
})
